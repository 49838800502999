import { defineStore } from 'pinia';
import { provideApolloClient } from '@vue/apollo-composable';
import { userStore } from '~/stores/user';
import { ACTION_MUTATION_DELETE, ACTION_MUTATION_INSERT, ACTION_MUTATION_UPDATE, ACTION_QUERY_LIST } from '~/graphql/action';
import { cleanJsonBeforeSend } from '~/helpers/data';
import type { Action, InsertAction, UpdateAction } from '~/types/action';

interface State {
  actions: Action[];
}

export const actionStore = defineStore({
  id: 'actionStore',
  state: (): State => ({
    actions: [],
  }),
  getters: {
    getActions(): Action[] {
      return this.actions;
    },
    getActiveActions(): Action[] {
      return this.actions.filter((action) => action.status === 'open');
    },
    getCloseActions(): Action[] {
      return this.actions.filter((action) => action.status === 'close');
    },
    getArchivedActions(): Action[] {
      return this.actions.filter((action) => action.status === 'archived');
    },
    getPotentialSaving(): number {
      return this.actions
        .filter((action) => action.potential_saving !== null)
        .filter((action) => action.status === 'open')
        .reduce((acc, action) => acc + (action.potential_saving ?? 0), 0);
    },
  },
  actions: {
    // Load actions from the API
    async loadActions(): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const actionsResponse = await apolloClient.query<{ actions: Action[] }>({
        query: ACTION_QUERY_LIST,
        variables: {
          site_id: globalStore().getSelectedSite,
        },
      });
      this.actions = actionsResponse.data.actions;
    },

    async insertActions(action: InsertAction): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const site_id = areaStore().getArea(action.area_id)?.site_id;
      if (!site_id) return;

      const populateAction: InsertAction = {
        ...action,
        site_id: site_id,
        status: 'open',
      };

      const { data } = await apolloClient.mutate<{ insert_actions_one: Action }>({
        mutation: ACTION_MUTATION_INSERT,
        variables: {
          object: populateAction,
        },
      });
      const insertedAction = data?.insert_actions_one;

      if (insertedAction && insertedAction.owner_id !== userStore().getCurrentUser.id) {
        notificationStore().createNotification({
          type: 'action-create',
          url: `/actions?actionId=${insertedAction.id}`,
          created_for: insertedAction.owner_id,
          data: {
            action_id: insertedAction.id,
          },
          created_by: userStore().getCurrentUser.id,
          read: false,
        });
      }

      await this.loadActions();
    },

    async updateAction(actionId: number, action: UpdateAction): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const objectData = cleanJsonBeforeSend({
        jsonObject: action,
        keys: ['area', 'owner', 'incident', 'id'],
      });
      const { data } = await apolloClient.mutate<{ update_actions_by_pk: Action }>({
        mutation: ACTION_MUTATION_UPDATE,
        variables: {
          id: actionId,
          object: objectData,
        },
      });
      const updatedAction = data?.update_actions_by_pk;

      if (updatedAction && updatedAction.owner_id !== userStore().getCurrentUser.id) {
        notificationStore().createNotification({
          type: 'action-create',
          url: `/actions?actionId=${updatedAction.id}`,
          created_for: updatedAction.owner_id,
          data: {
            action_id: updatedAction.id,
          },
          created_by: userStore().getCurrentUser.id,
          read: false,
        });
      }

      await this.loadActions();
    },

    async deleteAction(actionId: number): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      await apolloClient.mutate({
        mutation: ACTION_MUTATION_DELETE,
        variables: {
          id: actionId,
        },
      });

      await this.loadActions();
    },

    // ========== Incident
    getActiveActionsByIncident(incidentId: number): Action[] {
      return this.actions.filter(
        (action) => action.site_id === globalStore().getSelectedSite && action.status === 'open' && action.incident?.id === incidentId,
      );
    },

    getCloseActionsByIncident(incidentId: number): Action[] {
      return this.actions.filter(
        (action) => action.site_id === globalStore().getSelectedSite && action.status === 'close' && action.incident?.id === incidentId,
      );
    },
  },
});
