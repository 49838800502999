import { useI18n } from 'vue-i18n';
import type { Header } from '~/components/ui/data/DataTable.vue';
import type { TableAction } from '~/components/ui/data/DataTableActions.vue';

export const useAction = () => {
  // Composables
  const { t } = useI18n();
  const selectedTab = ref(0);

  const tabs = ref([
    {
      id: 0,
      label: t('action_plan.open'),
    },
    {
      id: 1,
      label: t('action_plan.done'),
    },
    {
      id: 2,
      label: t('action_plan.archived'),
    },
  ]);

  const tableHeader = computed((): Header[] => {
    const headers: Header[] = [
      {
        key: 'name',
        label: t('action_plan.action_name'),
      },
      {
        key: 'area',
        label: t('action_plan.area'),
      },
      {
        key: 'priority',
        label: t('action_plan.priority'),
      },
      {
        key: 'saving',
        label: t('action_plan.potential_saving'),
      },
      {
        key: 'owner',
        label: t('action_plan.owner'),
      },
      {
        key: 'created_at',
        label: t('action.created_at'),
      },
    ];

    if (selectedTab.value === 1) {
      headers.push({
        key: 'impact',
        label: t('action_plan.impact'),
      });
    }

    // Actions
    if (selectedTab.value === 0 || selectedTab.value === 2) {
      headers.push({
        key: 'action',
        label: '',
        align: 'center',
      });
    }

    return headers;
  });

  const tableActions = computed((): TableAction[] => {
    const actions: TableAction[] = [];

    if (selectedTab.value === 0) {
      actions.push({
        key: 'close',
        label: t('action_plan.evaluate_action'),
        icon: 'SquareCheckBig',
        iconStokeWidth: 2,
      });
      actions.push({
        key: 'edit',
        label: t('action_plan.edit_action'),
        icon: 'Pen',
        iconStokeWidth: 2,
      });
      actions.push({
        key: 'archive',
        label: t('action_plan.archive_action'),
        icon: 'Archive',
        iconStokeWidth: 2,
      });
    } else if (selectedTab.value === 2) {
      actions.push({
        key: 'unarchive',
        label: t('action_plan.unarchive_action'),
        icon: 'Archive',
        iconStokeWidth: 2,
      });
    }

    return actions;
  });

  return { tableHeader, selectedTab, tableActions, tabs };
};
