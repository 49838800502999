<script setup lang="ts">
import dayjs from 'dayjs';
import { useAction } from '../../composables/action';

// Components
import actionManageModal from '../modales/actionManageModal.vue';
import actionEstimationModal from '../modales/actionEstimationModal.vue';
import { useI18n } from 'vue-i18n';
import type { Action } from '~/types/action';

// Composables
const route = useRoute();
const { tableHeader, selectedTab, tableActions, tabs } = useAction();
const { t } = useI18n();

// Stores
const actions = actionStore();
const toast = toastStore();

// Props & Emits
defineProps<{
  loading: boolean;
  error: Error | null;
}>();

// Data
const actionModal = ref(false);
const estimationModal = ref(false);
const selectedAction = ref<Action | null>(null);

// Methods
const clickItem = async (event: string, item: Action) => {
  if (event === 'edit') {
    selectedAction.value = item;
    actionModal.value = true;
  } else if (event === 'close') {
    selectedAction.value = item;
    estimationModal.value = true;
  } else if (event === 'archive') {
    appAlertStore().showAlert({
      title: t('global.delete'),
      type: 'warning',
      message: t('action_plan.form.delete_message'),
      successText: t('action_plan.action_archived'),
      button: t('global.archive'),
      callback: () => actions.updateAction(item.id, { status: 'archived' }),
    });
  } else if (event === 'unarchive') {
    await actions.updateAction(item.id, { status: 'open' });
    toast.showToast({ type: 'success', title: t('global.success'), message: t('action_plan.action_unarchived') });
    selectedTab.value = 0;
  }
};

const openModal = () => {
  selectedAction.value = null;
  actionModal.value = true;
};

// Computed
const getTableData = computed(() => {
  if (selectedTab.value === 0) {
    return actions.getActiveActions;
  } else if (selectedTab.value === 1) {
    return actions.getCloseActions;
  } else if (selectedTab.value === 2) {
    return actions.getArchivedActions;
  } else return [];
});

onMounted(() => {
  if (route.query.actionId) {
    const action = actions.getActions.find((action: Action) => action.id === parseInt(route.query.actionId as string));
    if (!action) return;
    selectedAction.value = action;
    actionModal.value = true;
    route.query.actionId = null;
  } else if (route.query.area_id) {
    actionModal.value = true;
  }
});
</script>
<template>
  <ui-card :title="$t('action_plan.list_of_action')">
    <template #header>
      <ui-button left-icon="Plus" @click="openModal()">
        {{ $t('action_plan.create_action') }}
      </ui-button>
    </template>

    <ui-tab v-model="selectedTab" :list-tabs="tabs" class="mb-4" />

    <ui-data-table :headers="tableHeader" :loading="loading" :items="getTableData" @click-row="clickItem('edit', $event)">
      <template #item-area="{ item }">
        <ui-badge color="blue">{{ item.area.name }}</ui-badge>
      </template>
      <template #item-owner="{ item }">
        <span v-if="item.owner">{{ item.owner.first_name }} {{ item.owner.last_name }}</span>
      </template>
      <template #item-deadline="{ item }">
        <div class="flex items-center">
          <ui-icon name="Calendar" class="mr-2 w-4.5" />
          {{ dayjs(item.deadline).format('DD/MM/YYYY') }}
        </div>
      </template>
      <template #item-impact="{ item }">
        <ui-badge v-if="item && item.week_impact_cost" :color="Math.sign(item.week_impact_cost) === 1 ? 'green' : 'red'">
          {{ item.week_impact_cost }} €
        </ui-badge>
        <ui-badge v-else color="gray">
          {{ $t('action_plan.no_evaluated') }}
        </ui-badge>
      </template>
      <template #item-saving="{ item }">
        <ui-badge v-if="item.potential_saving" color="orange">
          {{ `${item.potential_saving} €` }}
        </ui-badge>
      </template>
      <template #item-priority="{ item }">
        <ui-badge color="purple">
          {{ $t(`incidents.priority_${item.priority}`) }}
        </ui-badge>
      </template>
      <template #item-incident="{ item }">
        <router-link v-if="item.incident" :to="`/incidents/${item.incident.id}`">
          <ui-badge color="purple">#{{ item.incident.id }}</ui-badge>
        </router-link>
      </template>
      <template #item-created_at="{ item }">
        {{ dayjs(item.created_at).format('DD/MM/YYYY') }}
      </template>
      <template #item-action="{ item }">
        <ui-button color="secondary" size="xs" @click="clickItem('close', item)">
          {{ $t('action_plan.evalutate') }}
        </ui-button>
        <ui-data-table-actions v-if="tableActions.length !== 0" :items="tableActions" @click-item="clickItem($event, item)" />
      </template>
    </ui-data-table>
  </ui-card>

  <!-- Modal -->
  <action-manage-modal v-model="actionModal" :action="selectedAction" />
  <action-estimation-modal v-if="selectedAction" v-model="estimationModal" :action="selectedAction" />
</template>
