import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useI18n } from 'vue-i18n';
import type { Action } from '~/types/action';
import type { Select } from '~/components/ui/form/InputSelect.vue';

dayjs.extend(isBetween);

type MeterData = { consumption: number; cost: number };

export const useActionParams = () => {
  // Composables
  const { t } = useI18n();
  const { getConsumptionByMeter } = useApi();

  const references = ref<Select[]>([
    {
      key: 'time_slot',
      label: t('action_plan.the_time_slot'),
    },
    {
      key: 'value',
      label: t('action_plan.the_value'),
    },
  ]);

  const getConsumptioonFromMeter = async (options: {
    meterId: number;
    period: { period_start: string; period_end: string };
    stepInMinutes: number;
  }) => {
    const start = dayjs(options.period.period_start);
    const end = dayjs(options.period.period_end);

    const meterConsumptionResponse = await getConsumptionByMeter({
      meterId: options.meterId,
      start: options.stepInMinutes > 60 ? start.format('YYYY-MM-DD') : start.format('YYYY-MM-DDTHH:mm:ss'),
      end: options.stepInMinutes > 60 ? end.format('YYYY-MM-DD') : end.format('YYYY-MM-DDTHH:mm:ss'),
      aggregationLevel: options.stepInMinutes > 60 ? 'day' : 'hour',
    });

    const consumption = meterConsumptionResponse.reduce((acc: number, curr: { consumption_kwh: number }) => acc + curr.consumption_kwh, 0);
    const cost = meterConsumptionResponse.reduce((acc: number, curr: { cost: number }) => acc + curr.cost, 0);

    return {
      consumption,
      cost,
    };
  };

  const calculateImpactTimeSlots = async (options: { action: Action }) => {
    // If not time slots return
    if (!options.action.time_slots?.at(0) || !options.action.period_start || !options.action.period_end) {
      return {
        periodConsumptionCompare: null,
        periodConsumptionCostCompare: null,
        sign: null,
      };
    }

    // Data
    const meters = options.action.area.meters.filter((meter) => meter.meter_type.energy_type === 'Electricity');
    const metersDataOnTestedPeriod: MeterData = {
      consumption: 0,
      cost: 0,
    };
    const metersDateOnComparePeriod: MeterData = {
      consumption: 0,
      cost: 0,
    };

    await Promise.all([
      ...meters.map(async (meter) => {
        // Get consumption from tested period
        const { consumption: consumptionTestedPeriod, cost: costTestedPeriod } = await getConsumptioonFromMeter({
          meterId: meter.id,
          period: { period_start: options.action.period_start, period_end: options.action.period_end },
          stepInMinutes: 60,
        });
        metersDataOnTestedPeriod.consumption += consumptionTestedPeriod;
        metersDataOnTestedPeriod.cost += costTestedPeriod;
      }),
      ...meters.map(async (meter) => {
        // Period of the action
        const { consumption: consumptionAction, cost: costAction } = await getConsumptioonFromMeter({
          meterId: meter.id,
          period: { period_start: options.action.time_slots[0][0], period_end: options.action.time_slots[0][1] },
          stepInMinutes: 60,
        });
        metersDateOnComparePeriod.consumption += consumptionAction;
        metersDateOnComparePeriod.cost += costAction;
      }),
    ]);

    return {
      periodConsumptionCompare: Math.round(metersDateOnComparePeriod.consumption - metersDataOnTestedPeriod.consumption),
      periodConsumptionCostCompare: Math.round(metersDateOnComparePeriod.cost - metersDataOnTestedPeriod.cost),
      sign: Math.sign(Math.round(metersDateOnComparePeriod.cost - metersDataOnTestedPeriod.cost)),
    };
  };

  const calculateImpactValue = async (options: { action: Action }) => {
    // If no value return
    if (!options.action.value || !options.action.period_start || !options.action.period_end) {
      return {
        periodConsumptionCompare: null,
        periodConsumptionCostCompare: null,
        sign: null,
      };
    }

    // Data
    const meters = options.action.area.meters.filter((meter) => meter.meter_type.energy_type === 'Electricity');
    const metersDataOnTestedPeriod: MeterData = {
      consumption: 0,
      cost: 0,
    };

    // Get consumption from tested period
    await Promise.all(
      meters.map(async (meter) => {
        const { consumption, cost } = await getConsumptioonFromMeter({
          meterId: meter.id,
          period: { period_start: options.action.period_start, period_end: options.action.period_end },
          stepInMinutes: 60,
        });
        metersDataOnTestedPeriod.consumption += consumption;
        metersDataOnTestedPeriod.cost += cost;
      }),
    );

    return {
      periodConsumptionCompare: Math.round(metersDataOnTestedPeriod.consumption - options.action.value),
      periodConsumptionCostCompare: Math.round(metersDataOnTestedPeriod.cost - options.action.value),
      sign: Math.sign(Math.round(metersDataOnTestedPeriod.consumption - options.action.value)),
    };
  };

  return { references, calculateImpactTimeSlots, calculateImpactValue };
};
