<script setup lang="ts">
import { useField } from 'vee-validate';

const props = defineProps<{
  name: string;
  label?: string;
  hideDetails?: boolean;
}>();

const { value } = useField<boolean>(props.name);

const update = () => {
  value.value = value.value ? false : true;
};
</script>

<template>
  <div class="flex" :class="[hideDetails ? '' : 'mb-[16px]']">
    <div class="flex items-center justify-start cursor-pointer" @click="update()">
      <div
        :class="[
          value ? 'bg-[#1BA774]' : 'bg-gray-200',
          'relative inline-flex h-4 w-7 flex-shrink-0  rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#1BA774] focus:ring-offset-2',
        ]"
      >
        <span
          aria-hidden="true"
          :class="[
            value ? 'translate-x-3' : 'translate-x-0',
            'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          ]"
        />
      </div>
      <span as="span" class="ml-3 text-sm flex items-center">
        <span class="font-medium text-gray-900">{{ label }}</span>
      </span>
    </div>
  </div>
</template>
