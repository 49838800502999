<script lang="ts" setup>
// Imports
import { useField } from 'vee-validate';

// Props & Emits & Refs
const modelValue = defineModel();
const props = defineProps<{
  name: string;
  hideDetails?: boolean;
  label?: string;
  placeholder?: string;
  autocomplete?: string;
  required?: boolean;
  disabled?: boolean;
}>();

// Data
const { value, errorMessage } = useField<string>(props.name);
const input = ref(null);
const inputContainer = ref(null);

watchEffect(() => (modelValue.value = value.value));
</script>
<template>
  <div ref="inputContainer" class="w-full" :class="[hideDetails ? '' : 'mb-[16px]']">
    <label v-if="label" :class="errorMessage ? 'text-red-300' : 'text-gray-600'" class="font-medium text-sm mb-[8px] block text-left">
      {{ label }}
      <span v-if="required" class="text-red-500">*</span>
    </label>
    <div class="w-full relative">
      <textarea
        :id="name"
        ref="input"
        v-model="value"
        :name="name"
        aria-autocomplete="both"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :rows="5"
        resize="none"
        required
        :class="[
          errorMessage
            ? 'border-red-600 focus:border-red-600 focus:ring-red-100'
            : 'border-gray-300 focus:border-primary-500 focus:ring-primary-100',
        ]"
        :placeholder="placeholder"
        class="p-[12px] text-sm autofill:bg-white bg-white outline-none focus:ring-[2px] ring-offset-0 border-[1px] w-full text-[14px] text-gray-700 rounded-md"
      />
      <span class="absolute right-0 top-[40px] text-red-500 text-xs">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>
